<script lang="ts">
  import { afterUpdate, onMount } from "svelte";
  import Spinner from "./Spinner.svelte";
  export let src: string;
  export let title: string;
  export let alt: string;

  let prevSrc = src;
  let el;
  let loading = true;

  afterUpdate(() => {
    if (prevSrc !== src) {
      loading = true;
      prevSrc = src;
    }
  });

  onMount(() => {
    const pid = el.addEventListener("load", () => (loading = false));
    return () => pid && el.removeEventListener(pid);
  });
</script>

<img {src} {title} {alt} bind:this={el} />
{#if loading}
  <div class="spinner">
    <Spinner />
  </div>
{/if}

<style>
  img {
    user-select: none;
    max-width: 100vw;
    max-height: 100vh;
    background-color: #766b6f;
  }

  .spinner {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
</style>
