<script lang="ts">
  import Page from "./Page.svelte";
  import { router } from "tinro";
  import { KeyCode } from "./utils/keycode";
  import { ALT_TEXT } from "./utils/alt-text";
  export const PAGE_MAX = 92;
  export let page: string;

  // we have to coerce it into an integer and redirect if needed.
  $: num = parseInt(page, 10);

  $: {
    if (isNaN(num)) {
      router.goto("/");
    } else if (num.toString() !== page) {
      router.goto(`/page/${num}`);
    } else if (num < 0 || num > PAGE_MAX) {
      router.goto("/");
    }
  }

  function onKeydown(e: KeyboardEvent) {
    switch (e.code) {
      case KeyCode.Left:
        if (num > 0) {
          router.goto(`/page/${num - 1}`);
        }
        break;

      case KeyCode.Right:
      case KeyCode.Space:
        if (num < PAGE_MAX) {
          router.goto(`/page/${num + 1}`);
        }
    }
  }
</script>

<svelte:window on:keydown={onKeydown} />

<main>
  {#if num > 0}
    <a
      class="prev"
      aria-label="Page {num - 1} link"
      href="/page/{num - 1}"
      title="Page {num - 1}"
    >
      <span class="long">&larr; prev</span>
      <span class="short">&larr;</span>
    </a>
  {/if}

  <div class="page">
    <Page
      src="/images/page/{num}.jpg"
      title="Page {num} image"
      alt={ALT_TEXT[num]}
    />
  </div>

  {#if num < PAGE_MAX}
    <a
      class="next"
      aria-label="Page {num + 1} link"
      href="/page/{num + 1}"
      title="Page {num + 1}"
    >
      <span class="long">next &rarr;</span>
      <span class="short">&rarr;</span>
    </a>
  {/if}
</main>

<style>
  main {
    display: flex;
    height: 100%;
    justify-content: center;
  }

  main > div.page {
    align-items: center;
    display: flex;
  }

  a {
    align-self: stretch;
    box-sizing: border-box;
    display: flex;
    align-items: flex-end;
    width: 50vw;
    position: absolute;
    height: 100%;
    font-weight: bold;
    text-decoration: none;
  }

  a > span {
    color: white;
    border-radius: 2px;
    padding: 0.5rem 0.75rem 0.5rem 0.75rem;
    background-color: rgba(0, 0, 0, 0.7);
  }

  a > span.long {
    display: none;
  }

  a.next {
    right: 0;
    justify-content: flex-end;
  }

  a:hover,
  a:active {
    color: white;
  }

  a:active,
  a:focus {
    outline: 0;
  }

  a.prev {
    left: 0;
  }
  a.next:hover {
    background: linear-gradient(
      270deg,
      rgba(0, 0, 0, 0.25) 0%,
      rgba(0, 0, 0, 0) 50%
    );
  }

  a.prev:hover {
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.25) 0%,
      rgba(0, 0, 0, 0) 50%
    );
  }

  @media (min-width: 640px) {
    a > span.long {
      display: inline;
    }
    a > span.short {
      display: none;
    }
    a {
      padding: 1rem;
    }
  }
</style>
