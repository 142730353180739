export const ALT_TEXT = [
    "Transformer fable/testimonial: A witness statement (witness statement)",
    "I wanted to tell you something. An",
    "electricity substation looks like a house, but this is a lie. You",
    "can tell because you can hear its hum so you know it lies. Hum, as in a cartoon, where the vibration is",
    "left in the air as the guilty character walks away. Hum ",
    "as a child who looks at their hands in rhythm with the whole wide world. But the electricity substation is never guilty, not even as it vibes carcinogenic. So it stays planted",
    "still, not needing to walk anywhere else. I know about lying.It",
    "was at point of messy over-tight pubescence that the generators revealed themselves once and for all to be something other than houses, because one day",
    "one near me got opened up. Its doors were entered and exposed to the street, and its parts were touched ",
    "and altered by boiler suited men-coded beings, knelt where the switchboards were kept, and blue and green tangled wires spilled a little, stilled as if inert. Spilled guts, but no body feeling, no",
    `thrill to look at, not like images of operations or sex,
So I had looked at the insides of the generator, waited, staring. Then I had walked`,
    `away with my hands in my pockets.
’We’ve caught a live one’
How things get to be known, and then when they are. 
Well electricity is neither alive`,
    `nor dead and nor does it have any time to give, and so it is unpunishable. It just animates,

underneath, overhead, and beforehand.`,
    "In high school there was someone we’d started knowing, but not knowing. Knowing like highschool",
    "knowing where we were there just looking not knowing not to stare, where we just there coinciding on occasion. He’d",
    `started to come under the bridge with us, under the bridge in the school

under the bridge, which went over the stream between play-grounds, 
 away from the planned paths,`,
    "where fuzzed concrete and broken glass were, and the most amazing life from all that, the joy beamed light energies and the arrangements of post-industrial trashes as coincident prayers, or squished bud and brambles,",
    "trod puddle, flies upon flies buzzing the stench lines rising up, these waste tissues of manufactured objects and others, giving off likes, dislikes, unhappinesses, happinesses. Notifying no-one in particular.",
    "And this boy that we under the bridge started knowing, he drew hentai in the unregulated margins of school books for money. And also elsewhere he drew hentai. I was impressed that he could sketch vulvas so",
    "quickly, not being so certain of the formula myself::: AND his drawn men had feminine hips, I remember, they made me feel alien all over, the hips were feminine, the curve on the page over the margin under his pencil.",
    `At least I read them as feminine, (I knew that formula), and , looking over his shoulder as he came closer, a feminine hip in the margin, a buttock in curved lines lipping the holepunch. A penis, then
a speech bubble almost`,
    `popped so unsteady, and maybe sometimes there wouldn’t be a word in the bubble but a mark, like this ! > ?
Or sometimes, better, this ; ;;

And aside from pencilled margins he’d do drawings using the Paint programme on the school computers,`,
    "colour print, or b&w which;: pencil shading over the top, printed out and onto A4 paper, kept in a filofax, in the plastic wallets with hole punches on the transparent plastic, a heat pressed seam and a slightly",
    `rough texture like plastinated animal tongue, but clean.

And he would carry the whole file in a dirty rucksack and bring it out near the trees, and charge £5 per plastic walleted image.`,
    "He smelt of pickled onions, his eyes were an amberish brown colour. He had pinking acne on pale cream white, and a very beautiful roundish face, curly brown hair. Beautiful because even when his eyes shifted, even when he tried to remember something false",
    "(I had seen diagrams, you can tell the truth or the lie from the moving direction of the eyes as they try to shift privately) (I knew this also from YouTube true crime) he said it with such absolute believing reality.",
    "And we in the real under bridge, away from the alienating legitimacy of the actual playground, wanted to believe. He told us how he lived somewhere in Oldham, he",
    "had samurai swords at home, he had silk robes, jogging bottoms, he wrote kanji Japanese look here is how you write honour, here is how you write liquid, here is how you write fight, here is how you write lie;;;; he lived with his older brother, who mostly worked elsewhere.",
    "What I liked about his hentai drawings was you could see both inside and outside. Very simple and transparent. The boy would draw cross sections of organs entering organs, and then the written dialogue would be both thoughts and speech, both ",
    `witnessable and not witnessable. Simple single thoughts and shy words about fucking. Or even just the punctuation marks. So honest. But as if you could only have one thought at a time, which is a lie.

One day`,
    "I looked over his shoulder and saw him draw something that was not genitals. I looked again. It was a group of standing figures in silken robes, some with goggles, some with swords. I saw he was in some kind of eternal regulation",
    `with the paper. I interrupted him anyway. I wanted any kind of metal drag of his eyes towards me. 

‘Who are they?’`,
    `I did not get the metal drag of his eyes towards me.

Nonetheless in the weeks which followed he seemed to pay more attention to all of we, we under the bridge, because he `,
    `started to describe a group who were a threat to his life,
And they wore robes and goggles and wrote him notes in code, from an email address, sweetpea69@yahoo.com`,
    `The notes were like this, a phrase a day. Sometimes kind of ominous:
DEAD IN THE TRACKS`,
    `Sometimes insulting:

FUCK FACE :)

But often neither, just difficult to exactly understand:

LIQUIDE GUMME `,
    `TRUCK-THE-GO
YOU WANNA DANCE?`,
    `SWEETPEA SWEETPEA DON'T WORRY
DETAIN YOURSELF IMMEDIATE SHE`,
    `KNOW U ARE SAID YOU...
TAKE THE KNOTS AND EMAIL THEM T9 THE GRAVE`,
    `GRAVEN GRAVEN GRAVEN FACW

And as though speaking clearly through unregulated mist, he would tell of this dangerous group, and then watch for our response, or,`,
    `as if checking vital signs in patients in strong medical inducement,
he working experimentally, 
With his amber eyes,
And pickled onion smell.`,
    "I couldn’t know him or anything else, but I felt him as a definite something strong and peripheral, yes, stronger than the main image, but unseeable for fear of burning. You see he built up a story which grew in",
    `textures more real and dramatic than our own felt presences within our own bodies.

  In the corridors and classrooms and
  playgrounds of the school there were`,
    `laws and regulations which were inadequately
explained, and that had something to do with our
membership within an interchangeable and
infinitely substitutable crowd`,
    `The story about this group, about his own endangerment, which bore the rich feeling of glamour, amounted to lies I could list in bullet points:

- They poisoned my room with blue gases, look at my tongue, it’s blue`,
    `- They put radioactive dust all in my house, Don’t Touch Me Today Cos I’m Poisonous But Not To Beetles
- They watch me in the bath
- They came on Monday and left on Sunday`,
    `And one day:

- They punched my mum didn’t they (a long silence, noble) (he showed us watermarked pictures on his phone of a woman with bruises)

“I thought you didn’t even have`,
    `a mum,” someone said
“You don’t know shit,” he said.

I can’t tell you, in bullet points, about the resonating warmth that these stories had, their wobbling yolk.

*********`,
    "ID: A drawing of face, head on, in black lines on a grey background. The style is slightly juvenile. The face is gazing intently outwards. White computerised brushstroke is overlaid to look like the face is surrounded by mist.",
    `You know, you have a lot going on in your jurisdiction but not all of it can be governed like grass growth idk or idk other mechanisms.
The bullet point lies transformed into something`,
    `which did not bear the burden of proof, however, the transformation was a continuation of the same yolk
He started to say he was CHANNELING this dangerous`,
    "robed group WITH HIS MIND or ‘bodymind’ he said, and he started to write WORDS in the margins of the papers and on the drawings of the appropriated hentai bodies that he sold in his dirty rucksack",
    `, in the trees and now under the bridge. Under the bridge. Under the bridge with me and us.  I and we watched it happening. I and we saw it. The words were like this:

GRASS UNCHAINED`,
    "ELECTRICITY UNFOUND ME",
    "TAKE ME TO THE LEADER OF YOUR JURISDICTION AND I WILL SHOW YOU THAT NATURE WHICH CANNOT BE MOWED WEEKLY",
    "WHICH MECHANISMS LACK BINDING?",
    "THEY GRIT ME SO READILY",
    "YOU DESERVE RELIEF LIKE WE DESERVE ",
    `I’M HERE TO TEACH U SWINES

These words scribbled in the margins, but sometimes covering the whole picture, or sometimes in a speech bubble.

UNDER THE SCHOOL, THE WIRES YOU CAN’T SEE`,
    "And the drawings were passed around and they were noted and they were disseminated, yes, that’s the word, among the sperm cells children pupils staring and looking, and among the others who came.",
    "And I saw them, and they would read, transfixed, stayed in themselves but wanting to lurch somewhere else as well, waiting for the next image, de or un regulated but not exactly knowing why. De or",
    `un regulated differently than normal.
He got famed.
And so I and we started the`,
    `low humming in class, because when he hummed by himself
he was given a learning support assistant who sat near. And the learning support assistant was sent from an agency and got the gist of the thing`,
    "which was to support perhaps by saying his name on occasion and tapping the place where he should write the date and the title with a pencil. And no marginal drawings possible.",
    "But when we all hummed something else happened. We couldn’t all have learning support assistants, because the funding would not hold. The under fundaments were changed. Humming above the electricity sound. Because we",
    "had started to notice that there was an electricity sound, and that this was the regular sound, the regularity, the ruling. And maybe it wasn’t the sound of the electricity but",
    "of something else which had been organised ahead of time, by someone now elsewhere. Or perhaps not a someone, not a one person.",
    `And I was disturbed because
I knew more than I was ready for
And I was no more
A dizzy bitch
About these things.`,
    `One day an agency learning support assistant told someone else in charge
about his sold hentai drawings, the genitals upon genitals and within genitals, the innocent cartoon filth, and, while, the senior`,
    ` management team had overlooked his margin drawings for a long time and, but, 

 he got into trouble. This time.

Then we had a school assembly on how pornography is`,
    ` inappropriate and not something we should know about.

Then I saw him when I walked past the exclusion room within the school, through the gridded glass panel in the door.`,
    "The exclusion room is the bit in the school which holds exile from itself, but within itself. After you get to the exclusion room, you go to the pupil referral unit somewhere three buses away, or you go to another",
    ` school maybe, or something else happens, I don't know. I don't really know. The explanations only go to a certain point, no further.

************`,
    `but 

PLEZURE
PLEZURE
PLEZURE
PLEZURE
 Was written now on the walls on the noticeboards,`,
    ` in blue
Don’t know by who


************

While he stayed in the exclusion room, we moved on from under the bridge, had our kinship through sports instead.`,
    "And so we enjoyed sports as the only lawful place to spit blood or wear painful mouth guards, or move in agential consequences, together. Netball, the synchronised movements, met the need for becoming together a machine.",
    "Rugby met the need for strong violence which made our bodies reassured that they were really still there after all. Proprioceptivity is gettin bashed on all sides. We were not all strong at sports,",
    "some of us got overwhelmed by the social aspects of the play, for example, to receive all the signals from all the players was too much for some antennae. But still the body takes the breaks it can get,,, for we were not allowed to",
    " wrestle in the corridors, and for good reason. The school was a civilisation of law. All physical moving of others outside of the sports field was to be done, lawfully (ie. without enjoyment), as a form of restraint. Say for example",
    ` a pupil got out of hand, out of hand, out of hand.


And Once
I returned to under the bridge, quickly, alone,`,
    `Don’t know why was jealous of passed time, I guess.
I found a note, just in time,
and didn’t show it to anyone else, not wanting that kind of mass disregulation,`,
    ` of course not,
 I said
to myself
The note said

MEET ME AT THE BIG SUBSTATION TONIGHT AND I WILL EXPLAIN EVERYTHING

And so I went, without we.`,
    "And so at the substation, in the night time, was the low electric perma buzz, the cold and the night included in themselves, and so I walked through it, the night time, to",
    "the substation, which was the big meeting point of all electricity, or so I felt it must be. Pylons and pylons within a field, like the Natural World, the real metal plants. A",
    ` revelation with no house disguises big
enough and around it, a fence, 

and I did not see him where he told me, anywhere, until, just inside`,
    "the fence, I saw something glisten, and I looked harder and saw something red, glisten. And I looked hardest and saw curls snaggled in the fence, painfully torn, ",
    "and I walked in body shock pain towards the red glistening, and the curls, and to touch them, and to see in a thrill to look at it, the horror, the ",
    `hair, the matted hair, the glistening, the organic, the red.

But when I`,
    " touched the hair, I saw that it was made of polyester, a wig, and when I went to the red glistening, I smelt that it was not blood or real human organs, but a ",
    `lie of strawberry jelly
And a note, in the margins of a 
red red drawing,`,
    "THEY GOT ME IN THE END AND IT WAS GOOD",
    "And I turned around, to look at everything else around, to look at the whole world humming with itself, a discordant unaccountable event.",
    "And I understood that what I was seeing was an explanation of absence, and that I would never see him again, or that he had made a kind of justice for himself, but only in counter-lie. I looked at my hands.",
];
