<script lang="ts">
  import { Route } from "tinro";
  import Book from "./Book.svelte";
</script>

<Route path="/"><Book page={"0"} /></Route>
<Route path="/page/*">
  <Route path="/0" redirect="/" />
  <Route path="/:num" let:meta>
    <Book page={meta.params.num} />
  </Route>
</Route>
